<template>
  <aside class="app-sidebar group">
    <ul class="app-sidebar__list">
      <AppSidebarItem
        v-for="item in links"
        :key="item.name"
        :item="item"
        :is-parent-active="item.path === activeLinks.parentPath"
        :active-child-path="activeLinks.childPath"
      />
    </ul>
  </aside>
</template>

<script setup lang="ts">
import { AppSidebarLink } from "@/shared/types/components";
import AppSidebarItem from "@/components/app/AppSidebar/AppSidebarItem/AppSidebarItem.vue";
import { useActiveSidebarLinks } from "@/components/app/AppSidebar/composables/useActiveLink";

const { links } = defineProps<{
  links: AppSidebarLink[];
}>();

const { activeLinks } = useActiveSidebarLinks(links);
</script>

<style lang="scss" scoped>
@import "src/styles/colors";
@import "src/styles/functions";

.app-sidebar {
  display: flex;
  flex-direction: column;
  min-width: rem(52px);
  max-width: rem(52px);
  background-color: $white;
  width: fit-content;
  position: fixed;
  left: rem(20px);
  top: rem(94px);
  box-sizing: border-box;
  padding: 0;
  gap: 0;
  max-height: calc(100vh - rem(114px));
  height: auto;
  overflow-y: auto;
  border-radius: rem(8px);
  box-shadow:
    0 2px 5px 1px rgb(0 0 0 / 0.1),
    0 1px 5px -2px rgb(0 0 0 / 0.1);
  transition:
    min-width 0.1s ease,
    max-width 0.1s ease;

  &:hover {
    min-width: rem(164px);
    max-width: unset;
    z-index: 10;
    transition:
      min-width 0.1s ease,
      max-width 0.1s ease;
  }

  &__list {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}
</style>
