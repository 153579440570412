import { TBInvestor } from "@/shared/types/filters";
import { Investor, Investment } from "@/shared/types/investor";
import { Component } from "vue";
import {
  FundingRound,
  FundingRoundInvestor,
  GenericResource,
} from "@/shared/types/common";
import { AppTableRow } from "@/shared/types/components";
import { AppSearchbarOption } from "@/shared/types/components";

export interface BaseFundItem extends AppSearchbarOption {
  id?: number;
  name?: string;
  label?: string;
  description: string;
}

export interface Fund extends AppTableRow {
  id: number;
  name: string;
  website?: string;
  thesis?: BaseFundItem;
  thesisId?: number;
  type?: BaseFundItem;
  typeId?: number;
  limitedPartners?: Investor[];
  instrument?: BaseFundItem;
  instrumentId?: number;
  startDate?: string;
  endDate?: string;
  targetSize?: number;
  targetSizeUSD?: number;
  targetSizeEUR?: number;
  targetSizeCurrency?: string;
  targetSizeDate?: string;
  totalCapitalRaised?: number;
  totalCapitalRaisedUSD?: number;
  totalCapitalRaisedEUR?: number;
  totalCapitalRaisedCurrency?: string;
  totalCapitalRaisedDate?: string;
  deployedCapital?: number;
  deployedCapitalUSD?: number;
  deployedCapitalEUR?: number;
  deployedCapitalCurrency?: string;
  deployedCapitalDate?: string;
  realisedValue?: number;
  realisedValueUSD?: number;
  realisedValueEUR?: number;
  realisedValueCurrency?: string;
  realisedValueDate?: string;
  unrealisedValue?: number;
  unrealisedValueUSD?: number;
  unrealisedValueEUR?: number;
  unrealisedValueCurrency?: string;
  unrealisedValueDate?: string;
  portfolioCompanies?: FundInvestment[];
  netIRR?: number;
  investorIDs?: number[];
  managers?: TBInvestor[];
  vintageYear?: number;
}

export interface FundResource extends GenericResource {
  fundID?: number;
  fund?: Fund;
}

export interface BaseSectionProps {
  isAdminView?: boolean;
}

export interface LimitedPartnersSectionProps extends BaseSectionProps {
  rows: LimitedPartner[];
  investorID: number;
}

export interface InvestmentsSectionProps extends BaseSectionProps {
  rows: Investment[];
  hideControls: boolean;
  title: string;
}

export interface FundInvestmentsSectionProps extends BaseSectionProps {
  rows: FundInvestment[];
}

export interface ResourcesSectionProps extends BaseSectionProps {
  modelValue: FundResource[];
  class: string;
}

type SectionProps =
  | (Fund & BaseSectionProps)
  | LimitedPartnersSectionProps
  | InvestmentsSectionProps
  | FundInvestmentsSectionProps
  | ResourcesSectionProps;

export interface Section {
  label: string;
  id: string;
  component: string | Component;
  isExists: boolean;
  props?: SectionProps;
  children?: Section[];
  count?: number;
}

export interface LimitedPartnerSearchModel {
  fundID: number;
  fundType: FundType;
  searchText: string;
}

export interface LimitedPartnerModel {
  fundId: number;
  investorId: number;
}

export interface LimitedPartner extends AppTableRow {
  type: BaseFundItem;
  fundId: number;
  investor: Investor;
  fundsSubscribed: number;
}

export interface FundInvestment extends AppTableRow {
  coInvestors: FundingRoundInvestor[];
  dealDate: string;
  dealType: string;
  clientID: number;
  logoURL: string;
  name: string;
  pitchline: string;
}

export interface FundInvestmentModel {
  clientID: number;
  fundID: number;
  fundingRoundID: number;
}

export interface FundInvestmentFundingRound extends FundingRound {
  fundingRoundID: number;
}

export enum FundType {
  MANAGER = "MANAGER",
  PARTNER = "PARTNER",
}

export enum FundMoney {
  TARGET_SIZE = "targetSize",
  TOTAL_CAPITAL_RAISED = "totalCapitalRaised",
  DEPLOYED_CAPITAL = "deployedCapital",
  REALISED_VALUE = "realisedValue",
  UNREALISED_VALUE = "unrealisedValue",
}
