<template>
  <header
    class="flex justify-between items-center fixed w-full h-16 px-8 z-10 bg-white shadow"
  >
    <AppLink to="/dashboard" link-component="router-link">
      <BaseIcon
        icon="netZeroInsights"
        alt="Net Zero Insights"
        size="100px auto"
        class="mt-1"
      />
    </AppLink>

    <SearchForm v-model="searchResultsText" />

    <div class="flex items-center gap-4">
      <ActionButtons
        :web-customer="webCustomerStore.webCustomer"
        :claim-profile-url="claimProfileURL"
        v-model:show-add-company-modal="showAddCompanyModal"
        v-model:show-add-fund-modal="showAddFundModal"
      />
      <AppFloatingMenu
        v-model="isFloatingMenuOpen"
        :options="menuOptions"
        side="right"
      >
        <template #content>
          <UserAvatar :initials="userInitials" v-model="isFloatingMenuOpen" />
        </template>
      </AppFloatingMenu>
    </div>

    <AppNewCompanyModal
      v-model="showAddCompanyModal"
      :is-loading="isLoading"
      :name="searchResultsText"
      @add="handleAddNewCompany"
    />

    <FundOverviewEditSection v-model="showAddFundModal" :is-edit="false" />
  </header>
</template>

<script setup lang="ts">
import { ref, computed } from "vue";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import { useNavigationMenu } from "@/shared/composables/useNavigationMenu/useNavigationMenu";
import { useSearchNavigation } from "@/shared/composables/useSearchNavigation/useSearchNavigation";
import { ClaimProfileSteps } from "@/shared/types/profileClaim";
import AppNewCompanyModal from "@/components/dashboard/AppNewCompanyModal/AppNewCompanyModal.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import SearchForm from "@/components/app/AppHeader/components/SearchForm.vue";
import ActionButtons from "@/components/app/AppHeader/components/ActionButtons.vue";
import UserAvatar from "@/components/app/AppHeader/components/UserAvatar.vue";
import { NewCompanyModel } from "@/shared/types/organization";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import { redirect } from "@/shared/helpers/redirect/redirect";
import { AxiosError } from "axios";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import FundOverviewEditSection from "@/components/funds/FundOverviewEditSection/FundOverviewEditSection.vue";

const webCustomerStore = useWebCustomerStore();
const { searchResultsText } = useSearchResults();
const notificationsStore = useNotificationsStore();
const { menuOptions } = useNavigationMenu();
useSearchNavigation({
  searchText: searchResultsText,
});

const isFloatingMenuOpen = ref(false);
const showAddCompanyModal = ref(false);
const showAddFundModal = ref(false);
const isLoading = ref(false);

const userInitials = computed(() => {
  const { name, surname } = webCustomerStore.webCustomer;

  if (!name || !surname) return "";

  return `${name[0]}${surname[0]}`.toUpperCase();
});

const claimProfileURL = computed(() => {
  return `/claim-profile/${webCustomerStore.webCustomer.profileClaimToken}/${ClaimProfileSteps.BASIC_INFORMATION}`;
});

const handleAddNewCompany = async (newCompany: NewCompanyModel) => {
  try {
    isLoading.value = true;
    const { data } = await organizationsService.addNewCompany(newCompany);

    if (data.directURL) {
      redirect(`/organization/${data.directURL}`);
      showAddCompanyModal.value = false;
    }
  } catch (e) {
    const isInvalidUrl = (e as AxiosError).response?.status === 400;
    notificationsStore.notify(
      isInvalidUrl ? "Invalid URL" : defaultServerErrorMessage,
      "danger",
      3000,
    );
  } finally {
    isLoading.value = false;
  }
};
</script>
