import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-empty-layout" }
const _hoisted_2 = { class: "app-empty-layout__navigation-bar" }
const _hoisted_3 = { class: "app-empty-layout__body" }
const _hoisted_4 = { class: "app-empty-layout__body__content" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { ref, computed } from "vue";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import { useUiStore } from "@/stores/uiStore/useUiStore";
import AppLink from "@/components/app/AppLink/AppLink.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEmptyLayout',
  setup(__props) {

const webCustomerStore = useWebCustomerStore();
const uiStore = useUiStore();

const customLogo = computed(() => {
  return uiStore.customLogo;
});

const bookCallModal = ref(false);

const handleOpenBookCallModal = () => {
  bookCallModal.value = !bookCallModal.value;
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(webCustomerStore).showSubscriptionBanner)
      ? (_openBlock(), _createBlock(AppExpiryBanner, {
          key: 0,
          onOpenBookCallModal: handleOpenBookCallModal
        }))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_2, [
      (!customLogo.value)
        ? (_openBlock(), _createBlock(AppLink, {
            key: 0,
            to: "/dashboard",
            "link-component": "router-link",
            class: "app-empty-layout__navigation-bar__brand"
          }, {
            default: _withCtx(() => [
              _createVNode(BaseIcon, {
                icon: "netZeroInsights",
                alt: "Net Zero Insights",
                class: "app-empty-layout__navigation-bar__brand"
              })
            ]),
            _: 1
          }))
        : (customLogo.value === 'climateKIC')
          ? (_openBlock(), _createBlock(AppLink, {
              key: 1,
              href: "https://www.climate-kic.org/",
              target: "_blank"
            }, {
              default: _withCtx(() => [
                _createVNode(BaseIcon, {
                  icon: customLogo.value,
                  alt: "climate KIC",
                  size: "130px auto"
                }, null, 8, ["icon"])
              ]),
              _: 1
            }))
          : (_openBlock(), _createBlock(BaseIcon, {
              key: 2,
              icon: customLogo.value,
              alt: "Net Zero Insights",
              size: "130px auto"
            }, null, 8, ["icon"])),
      (_unref(webCustomerStore).webCustomer.preview)
        ? (_openBlock(), _createBlock(AppButton, {
            key: 3,
            href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
            label: "Upgrade",
            size: "small",
            "font-size": "12px",
            height: "30px"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(BookCallModal, {
      modelValue: bookCallModal.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((bookCallModal).value = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("main", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

})