import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { AppBadgeType } from "@/shared/enums/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppBadge',
  props: {
    type: { default: () => (AppBadgeType.PRIMARY) }
  },
  setup(__props: any) {



return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("span", {
    class: _normalizeClass([
      'text-xs inline-block',
      {
        'bg-primary text-white pointer-events-none leading-none p-1 rounded-lg font-semibold':
          _ctx.type === _unref(AppBadgeType).PRIMARY,
        'bg-gray-light py-1 px-2.5 rounded-full font-light text-black':
          _ctx.type === _unref(AppBadgeType).SECONDARY,
      },
    ])
  }, [
    _renderSlot(_ctx.$slots, "default", {}, () => [
      _cache[0] || (_cache[0] = _createTextVNode("BETA"))
    ])
  ], 2))
}
}

})