import router from "@/router";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";

export const redirect = (path: string, isBlank?: boolean) => {
  const { getNewTabEnabled } = useWebCustomerStore();

  if (getNewTabEnabled || isBlank) {
    window.open(path, "_blank");
  } else {
    router.push(path);
  }
};
