import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { defineAsyncComponent, ref, watch, useTemplateRef } from "vue";
import { AppSearchbarOption } from "@/shared/types/components";
import { debounce } from "@/shared/helpers/debounce/debounce";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppDropDown from "@/components/app/AppDropDown/AppDropDown.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSearchbar',
  props: {
    options: {},
    placeholder: { default: "Type to search" },
    keyProperty: { default: "id" },
    labelProperty: {},
    searchProperty: { default: "label" },
    allowHtml: { type: Boolean },
    isLocalSearch: { type: Boolean },
    clearable: { type: Boolean },
    keepSelection: { type: Boolean },
    isDisabled: { type: Boolean },
    isEmptyStateClickable: { type: Boolean },
    isLoading: { type: Boolean },
    error: { type: Boolean },
    maxResults: { default: 5 },
    debounceFunction: { type: Function },
    debounceDuration: { default: 500 }
  },
  emits: ["select", "input", "clear"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const AppButton = defineAsyncComponent(
  () => import("@/components/app/AppButton/AppButton.vue"),
);



const emit = __emit;

const dropDownComponent = useTemplateRef<typeof AppDropDown>("drop-down");
const searchModel = ref("");
const selectedResult = ref<T>();

const fetchSearchResults =
  __props.debounceFunction && debounce(__props.debounceFunction, __props.debounceDuration);

const handleSelect = (option: T) => {
  if (option[__props.keyProperty] !== -1 || __props.isEmptyStateClickable) {
    emit("select", option);
  }

  if (__props.keepSelection && option[__props.keyProperty] !== -1) {
    searchModel.value = (option[__props.searchProperty] as string) || searchModel.value;

    selectedResult.value = option;
  } else {
    searchModel.value = "";
  }
};

const clearSearchbar = () => {
  searchModel.value = "";

  emit("clear");
};

watch(
  () => searchModel.value,
  (newValue) => {
    emit("input", newValue);

    if (
      !selectedResult.value ||
      selectedResult.value[__props.searchProperty] !== newValue
    ) {
      fetchSearchResults?.(newValue);
    }
  },
);

__expose({
  searchModel,
  selectedResult,
  clearSearchbar,
  dropDownComponent,
});

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(AppDropDown, {
    "search-value": searchModel.value,
    "is-loading": _ctx.isLoading,
    "allow-html": _ctx.allowHtml,
    options: _ctx.options,
    "is-empty-state-clickable": _ctx.isEmptyStateClickable,
    "key-property": _ctx.keyProperty,
    "label-property": _ctx.labelProperty,
    "search-property": _ctx.searchProperty,
    "is-local-search": _ctx.isLocalSearch,
    "max-results": _ctx.maxResults,
    onSelect: handleSelect,
    ref: "drop-down"
  }, {
    target: _withCtx(({ focus, reset }) => [
      _createVNode(AppInput, {
        modelValue: searchModel.value,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((searchModel).value = $event)),
        error: _ctx.error,
        icon: "search",
        placeholder: _ctx.placeholder,
        disabled: _ctx.isDisabled,
        onInput: reset,
        onFocus: ($event: any) => (focus(true))
      }, _createSlots({ _: 2 }, [
        (_ctx.clearable && searchModel.value)
          ? {
              name: "trailing",
              fn: _withCtx(() => [
                _createVNode(_unref(AppButton), {
                  type: "empty",
                  icon: "cross",
                  "icon-size": "10px",
                  padding: "6px",
                  onClick: clearSearchbar
                })
              ]),
              key: "0"
            }
          : undefined
      ]), 1032, ["modelValue", "error", "placeholder", "disabled", "onInput", "onFocus"])
    ]),
    result: _withCtx(({ option, index, selectedIndex }) => [
      _renderSlot(_ctx.$slots, "result", {
        option: option,
        index: index,
        selectedIndex: selectedIndex,
        text: searchModel.value
      })
    ]),
    append: _withCtx(() => [
      _renderSlot(_ctx.$slots, "append")
    ]),
    _: 3
  }, 8, ["search-value", "is-loading", "allow-html", "options", "is-empty-state-clickable", "key-property", "label-property", "search-property", "is-local-search", "max-results"]))
}
}

})