import { MainFilter } from "@/shared/types/filters";

export enum SavedSearchType {
  COMPANIES = "COMPANIES",
  FUNDING_ROUNDS = "FUNDING_ROUNDS",
  INVESTORS = "INVESTORS",
}

export const mapEmailFrequency = {
  Daily: "DAILY",
  Weekly: "WEEKLY",
  "Bi-Weekly": "BI_WEEKLY",
  Monthly: "MONTHLY",
  None: "NONE",
};

export const mapEmailFrequencyReverse = {
  DAILY: "Daily",
  WEEKLY: "Weekly",
  BI_WEEKLY: "Bi-Weekly",
  MONTHLY: "Monthly",
  NONE: "None",
};

export interface EmailFrequency {
  id: number;
  label: "Daily" | "Weekly" | "Bi-Weekly" | "Monthly" | "None";
  definition?: string;
}

export interface WCSavedSearch {
  id: number;
  creationDate: string;
  lastSearchedDate?: string;
  name: string;
  updatesFrequency: EmailFrequency;
  searchType: SavedSearchType;
  salesforceSyncing: boolean;
  salesforceLastSyncDate?: string;
  affinitySyncing: boolean;
  affinityListID?: number;
  mondaySyncing: boolean;
  mondayLastSyncDate?: string;
  mondayListID?: number;
  affinityLastSync?: string;
  affinityLinkID?: number;
  mondayLinkID?: number;
  notification: boolean;
  mainFilter?: MainFilter;
}

export interface SavedSearchModel {
  name: string;
  searchType: SavedSearchType;
  notification: boolean;
  updatesFrequency: EmailFrequency;
  mainFilter?: MainFilter;
}

export enum PortfolioType {
  ORGANIZATION = "organization",
  INVESTOR = "investor",
}
