import { defineStore } from "pinia";
import { ref } from "vue";

export const useUiStore = defineStore("ui", () => {
  const customLogo = ref<string>();

  function setCustomLogo(logo?: string) {
    customLogo.value = logo;
  }

  return {
    customLogo,
    setCustomLogo,
  };
});
