import { defineStore } from "pinia";
import {
  Contact,
  MainFilter,
  SimilarCompaniesFilter,
  PatentOffice,
  TBImpactScoreBucket,
} from "@/shared/types/filters";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import {
  COFundingRoundsPrint,
  Startup,
  PatentStatus,
  Patent,
} from "@/shared/types/common";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { formatCurrencyHuman } from "@/shared/helpers/formatCurrencyHuman/formatCurrencyHuman";
import {
  EmployeesGrowthJSON,
  HQRegion,
  OrganizationNote,
  SearchLocation,
  LocationAPIPayload,
} from "@/shared/types/organization";
import { AxiosError } from "axios";
import useFreeTrialStore from "@/stores/useFreeTrialStore/useFreeTrialStore";
import router from "@/router";
import portfoliosService from "@/shared/services/portfoliosService/portfoliosService";
import { abortErrorName } from "@/shared/constants/errors";

export default defineStore("organizations", {
  state: () => {
    return {
      organizations: [] as Startup[],
      impactScoreBuckets: [] as TBImpactScoreBucket[],
      organization: {} as Startup,
      startupsTotalFunding: 0,
      isLoading: false,
      count: 0,
    };
  },

  actions: {
    setOrganisations(newOrganisation: Startup[]) {
      this.organizations = newOrganisation;
    },

    setOrganisationCount(newCount: number) {
      this.count = newCount;
    },

    setIsloading(newValue: boolean) {
      this.isLoading = newValue;
    },

    async getStartupList(
      mainFilter: MainFilter,
      errorHandler?: () => unknown,
      abortSignal?: AbortSignal,
    ) {
      try {
        this.isLoading = true;

        const { data } = await organizationsService.getStartupList(
          mainFilter,
          abortSignal,
        );

        this.organizations = data.results;
        this.startupsTotalFunding = data.startupsTotalFunding;
        this.count = data.count;
        this.isLoading = false;
      } catch (e) {
        if ((e as AxiosError)?.response?.status === 402) {
          const { showFreeTrialModal } = useFreeTrialStore();

          showFreeTrialModal("SEARCH_LIMIT_EXCEEDED");
          errorHandler?.();
        }

        this.organizations = [];
        this.startupsTotalFunding = 0;
        this.count = 0;

        if ((e as AxiosError).name !== abortErrorName) {
          this.isLoading = false;
        }
      }
    },

    async getSimilarCompanies(
      mainFilter: SimilarCompaniesFilter,
      abortSignal?: AbortSignal,
    ) {
      try {
        this.isLoading = true;

        const response = await organizationsService.getSimilarCompanies(
          mainFilter,
          abortSignal,
        );

        if (response) {
          this.setOrganisations(response.data.results);
          this.count = response.data.count;
        }
      } catch (e) {
        if ((e as AxiosError)?.response?.status === 402) {
          const { showFreeTrialModal } = useFreeTrialStore();

          showFreeTrialModal("SEARCH_LIMIT_EXCEEDED");
        }

        this.organizations = [];
        this.startupsTotalFunding = 0;
        this.count = 0;
      } finally {
        this.isLoading = false;
      }
    },

    async getRecentlyAddedOrganizations(abortSignal?: AbortSignal) {
      this.isLoading = true;

      try {
        const response =
          await organizationsService.getRecentlyAddedOrganizations(
            () => (this.isLoading = false),
            abortSignal,
          );

        if (response) {
          this.organizations = response.data;

          this.count = response.data.length;
        }
      } catch (e) {
        this.organizations = [];

        this.count = 0;
      }
    },

    async getTbImpactScoreBuckets(abortSignal?: AbortSignal) {
      try {
        const response =
          await organizationsService.getTbImpactScoreBuckets(abortSignal);

        if (response) {
          this.impactScoreBuckets = response.data;
        }

        return response;
      } catch (e) {
        this.impactScoreBuckets = [];
      }
    },

    async getStartup(
      directUrl: string,
      abortSignal?: AbortSignal,
      errorHandler?: () => unknown,
    ) {
      try {
        this.organization = (
          await organizationsService.getStartup(directUrl, abortSignal)
        ).data;
      } catch (e) {
        const errorStatusCode = (e as AxiosError)?.response?.status as number;
        if (errorStatusCode === 402) {
          errorHandler?.();
        } else if ([400, 404, 500].includes(errorStatusCode)) {
          router.replace({ name: "PageNotFound" });
        }

        this.organization = {} as Startup;
      }
    },

    async getFundingRoundsPrintByClientId(
      clientId: number,
      abortSignal?: AbortSignal,
    ): Promise<COFundingRoundsPrint[]> {
      try {
        const fundingRoundsPrint = (
          await organizationsService.getFundingRoundsPrintByClientId(
            clientId,
            abortSignal,
          )
        ).data;

        const { isCurrencyEuro, getCurrency } = useWebCustomerStore();

        let raisedToDate = 0;

        for (let i = fundingRoundsPrint.length - 1; i >= 0; i--) {
          const round: COFundingRoundsPrint = fundingRoundsPrint[i];

          const roundAmount = isCurrencyEuro
            ? round.roundAmount
            : round.roundAmountUSD;

          raisedToDate += roundAmount || 0;

          round.raisedToDate = raisedToDate
            ? formatCurrencyHuman(raisedToDate, 2, getCurrency)
            : "-";
        }

        return fundingRoundsPrint;
      } catch (e) {
        return [];
      }
    },

    async getFiledPatentsByClientId(
      clientId: number,
      abortSignal?: AbortSignal,
    ): Promise<number> {
      try {
        return (
          await organizationsService.getFiledPatentsByClientId(
            clientId,
            abortSignal,
          )
        ).data;
      } catch (e) {
        return 0;
      }
    },

    async getPatentsCountByClientIdAndStatus(
      clientId: number,
      status: PatentStatus,
      abortSignal?: AbortSignal,
    ) {
      try {
        return (
          await organizationsService.getPatentsCountByClientIdAndStatus(
            clientId,
            status,
            abortSignal,
          )
        ).data;
      } catch (e) {
        return 0;
      }
    },

    async getLastPatentActivityByClientId(
      clientId: number,
      abortSignal?: AbortSignal,
    ) {
      try {
        return (
          await organizationsService.getLastPatentActivityByClientId(
            clientId,
            abortSignal,
          )
        ).data;
      } catch (e) {
        return "";
      }
    },

    async getCompanyContactsByClientId(
      clientId: number,
      abortSignal?: AbortSignal,
    ): Promise<Contact[]> {
      try {
        return (
          await organizationsService.getCompanyContactsByClientId(
            clientId,
            abortSignal,
          )
        ).data;
      } catch (e) {
        return [];
      }
    },

    async getPatentOffices(): Promise<PatentOffice[]> {
      try {
        return (await organizationsService.getPatentOffices()).data;
      } catch (e) {
        return [];
      }
    },

    async getPatentsByClientId(
      clientId: number,
      abortSignal?: AbortSignal,
    ): Promise<Patent[]> {
      try {
        return (
          await organizationsService.getPatentsByClientId(clientId, abortSignal)
        ).data;
      } catch (e) {
        return [];
      }
    },

    removeOrganizationFromLocalStoreList(clientIds: number[]) {
      this.organizations = this.organizations.filter(
        (organization) => !clientIds.includes(organization.clientID),
      );

      this.count -= clientIds.length;
    },

    async getSearchLocations(
      location: string,
      abortSignal?: AbortSignal,
    ): Promise<SearchLocation[]> {
      try {
        return (
          await organizationsService.getSearchLocations(location, abortSignal)
        ).data;
      } catch (e) {
        return [];
      }
    },

    async getSearchLocationByID(
      id: number,
      abortSignal?: AbortSignal,
    ): Promise<SearchLocation | undefined> {
      try {
        return (
          await organizationsService.getSearchLocationByID(id, abortSignal)
        ).data;
      } catch (e) {
        return undefined;
      }
    },

    async getHQRegions(
      region: string,
      abortSignal?: AbortSignal,
    ): Promise<HQRegion[]> {
      try {
        return (await organizationsService.getHQRegions(region, abortSignal))
          .data;
      } catch (e) {
        return [];
      }
    },

    async getStartupCount(mainFilter: MainFilter, abortSignal?: AbortSignal) {
      try {
        return (
          await organizationsService.getStartupCount(mainFilter, abortSignal)
        ).data.count;
      } catch (e) {
        return -1;
      }
    },

    async addOrganizationNote(
      organizationNote: OrganizationNote,
      fromDirectPage: boolean,
    ) {
      try {
        await organizationsService.addNote(organizationNote);

        if (fromDirectPage) {
          this.organization.note = organizationNote.note;
        } else {
          const foundOrganization = this.organizations.find(
            (organization) =>
              organization.clientID === organizationNote.clientID,
          );

          if (foundOrganization) {
            foundOrganization.note = organizationNote.note;
          }
        }
      } catch (e) {
        console.error(e);
      }
    },

    setSeen(id: number) {
      const foundOrganization = this.organizations.find((org) => org.id === id);
      if (foundOrganization) {
        foundOrganization.lastSeenDate = new Date().toISOString();
      }
    },

    async updateOrganizationLogo(file: File) {
      await organizationsService.updateOrganizationLogo(
        this.organization.clientID,
        file,
      );
    },

    async updateOrganizationName(newName: string) {
      await organizationsService.updateOrganizationName(
        this.organization.clientID,
        newName,
      );

      this.organization.name = newName;
    },

    async updateOrganizationDescription(
      description: string,
      abortSignal?: AbortSignal,
    ) {
      await organizationsService.updateOrganizationDescription(
        this.organization.clientID,
        description,
        abortSignal,
      );

      this.organization.pitchLine = description;
    },

    async updateOrganizationWebsite(newWebsite: string) {
      await organizationsService.updateOrganizationWebsite(
        this.organization.clientID,
        newWebsite,
      );

      this.organization.website = newWebsite;
    },

    async updateOrganizationLocation(location: LocationAPIPayload) {
      return organizationsService.updateOrganizationLocation(
        this.organization.clientID,
        location,
      );
    },

    async updateOrganizationEmployees(currentEmployeeCount: string) {
      return organizationsService.updateOrganizationEmployees(
        this.organization.clientID,
        Number(currentEmployeeCount),
      );
    },

    async updateOrganizationEmployeesGrowthJSON(json: string) {
      const { webCustomer } = useWebCustomerStore();

      const data: EmployeesGrowthJSON = {
        clientID: this.organization.clientID,
        source: `${webCustomer.name.toLowerCase()}_${webCustomer.surname.toLowerCase()}`,
        employeesGrowthJSON: json,
      };

      return organizationsService.updateOrganizationEmployeesGrowthJSON(data);
    },

    async updateOrganizationLinkedin(newLinkedinUrl: string) {
      await organizationsService.updateOrganizationLinkedin(
        this.organization.clientID,
        newLinkedinUrl,
      );

      this.organization.linkedinURL = newLinkedinUrl;
    },

    async updateOrganizationFoundedYear(foundedYear: string) {
      await organizationsService.updateOrganizationFoundedYear(
        this.organization.clientID,
        Number(foundedYear),
      );

      this.organization.foundedDate = Number(foundedYear);
    },

    async updateOrganizationAlternativeNames(alternativeNames: string[]) {
      await organizationsService.updateOrganizationAlternativeNames(
        this.organization.clientID,
        alternativeNames,
      );
    },

    async updateOrganizationLegalNames(legalNames: string[]) {
      await organizationsService.updateOrganizationLegalNames(
        this.organization.clientID,
        legalNames,
      );
    },

    async removeCompanyFromPortfolio(
      portfolioId: number,
      clientsIds: number[],
    ) {
      clientsIds.forEach(async (clientId) => {
        try {
          await portfoliosService.removeCompanyFromPortfolio(
            portfolioId,
            clientId,
          );

          this.organizations = this.organizations.filter(
            (organization) => organization.clientID !== clientId,
          );

          this.count--;
        } catch (error) {
          console.error(error);
        }
      });
    },

    resetCount() {
      this.count = 0;
    },
  },
});
