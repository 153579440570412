import { TagPOJO } from "@/shared/types/profileClaim";
import { AppSearchbarOption, AppTab } from "@/shared/types/components";
import { TBTag } from "@/shared/types/filters";
import { Currency } from "@/shared/types/common";
import { TaxonomySearchResult } from "@/shared/types/taxonomy";

export enum DEEP_DIVE_TABS {
  MARKET_DATA = "marketData",
  MARKET_MAP = "marketMap",
  METHODOLOGY = "methodology",
  INNOVATION_LANDSCAPE = "innovationLandscape",
}

export interface DeepDivePage {
  id: number;
  dealsCount?: number;
  dealsCountHistorical?: number;
  fundingAmount?: number;
  fundingAmountHistorical?: number;
  description: string;
  investorsCount?: number;
  name: string;
  organisationsCount?: number;
  tagId?: number;
  tag?: TagPOJO;
  visible: boolean;
  manuallyCurated?: boolean;
  publicPage?: boolean;
  priority?: number;
  url: string;
  thumbnailURL: string;
  downloadURL?: string;
  challengeAreaID: number;
  taxonomyItem?: TaxonomySearchResult;
  taxonomyURL?: string;
  pdfDownloadURL?: string;
  docDownloadURL?: string;
}

export interface DeepDivePageFirstTabBox {
  id: number;
  deepDivePageId: number;
  order: number;
  title: string;
}

export interface DeepDivePageFirstTabSubsection {
  id: number;
  boxId: number;
  order: number;
  firstText?: string;
  firstActionTitle?: string;
  thirdText?: string;
}

export interface DeepDivePageSecondTab {
  id: number;
  deepDivePageId: number;
  order: number;
  title: string;
  text: string;
}

export interface DeepDiveMarketMapSegment {
  id?: number;
  deepDivePageID?: number;
  fakeID?: string;
  tags?: TagPOJO[];
  selectedTag?: TagPOJO;
  title: string;
  tagIDs?: number[];
}

export interface DeepDiveMarketMapPopupData {
  updatedSegments: DeepDiveMarketMapSegment[];
  createdSegments: DeepDiveMarketMapSegment[];
}

export interface DeepDiveMarketMapSegmentTag {
  id: number;
  segmentID: number;
  tagID: number;
}

export interface SectionModel<T> {
  title: string;
  position: number | string;
  deepDivePageId: number;
  id?: number;
  subsections?: T[];
  text?: string;
}

export type SolutionSection = SectionModel<SolutionSubsectionModel>;

export interface FullSectionModel<T> extends SectionModel<T> {
  expanded: boolean;
  isSaved: boolean;
  isLoading: boolean;
  fakeId: string;
}

export type SolutionSectionModel = FullSectionModel<SolutionSubsection>;

export interface SolutionSubsection {
  text: string;
  position: number | string;
  solutionId?: number;
  deepDiveSolutionId?: number;
  id?: number;
}

export interface BreakdownOptions {
  id: number;
  label: string;
  tagBreakdown: boolean;
}

export interface SubsectionModel {
  position: number | string;
  isSaved: boolean;
  isLoading: boolean;
  id?: number;
  fakeId: string;
}

export interface DeepDiveGraphDeleteEmitter {
  graphId: number;
  sectionId: number;
  subsectionId: number;
  done: () => unknown;
}

export interface SolutionSubsectionModel
  extends SolutionSubsection,
    SubsectionModel {}

export interface DeepDiveDropDownDeleteEmitter {
  sectionId: number;
  subsectionId?: number;
  done: () => unknown;
}

export interface DeepDivePageModal {
  name: string;
  visible: boolean;
  description: string;
  thumbnailURL?: string;
  downloadURL?: string;
  challengeAreaID?: number;
  taxonomyItemID?: number;
  manuallyCurated: boolean;
  publicPage: boolean;
  priority?: number;
}

export enum DELETE_SECTIONS_ITEM_TYPE {
  SECTION = "section",
  SUB_SECTION = "sub-section",
}

export interface DeleteSectionsModalData {
  show: boolean;
  content: string;
  title: string;
  selectedId?: number;
  type: DELETE_SECTIONS_ITEM_TYPE;
  isLoading: boolean;
}

export type MarketDataSection = SectionModel<MarketDataSubsectionModel>;

export interface MarketDataSubsection {
  firstText: string;
  firstActionTitle: string;
  position: number | string;
  title?: string;
  marketDataId?: number;
  id?: number;
  chartOptions?: MarketDataGraphModel;
}

export interface MarketDataSubsectionModel
  extends MarketDataSubsection,
    SubsectionModel {}

export enum MARKET_DATA_GRAPH_TYPES {
  BAR = "BAR",
  PIE = "PIE",
}

export interface Breakdown {
  breakdownID: number;
  breakdownTagIDs?: number[];
  breakdownTags?: TBTag[];
}

export interface MarketDataGraphModel {
  xAxisIDs?: number[];
  yAxisIDs?: number[];
  chartValues?: string[];
  marketDataSubsectionID?: number;
  deepDivePageID?: number;
  chartType: MARKET_DATA_GRAPH_TYPES | "";
  tagIDs?: number[];
  tags?: TBTag[];
  breakdowns?: Breakdown[];
  graphID?: number;
  startYear?: number;
  includeCompaniesOutsideDeepDive: boolean;
}

export interface MarketDataGraphModelEmitter extends MarketDataGraphModel {
  done: () => unknown;
  sectionId?: number;
}

export interface DeepDiveCustomChartOptionsType {
  title: string;
  options: AppSearchbarOption[];
  value: AppSearchbarOption;
}

export interface DeepDivePieChartResponse {
  label: string;
  content: {
    value: number;
    percentage: number;
  };
}

export type ChartValue = "ABSOLUTE" | "PERCENTAGE";

export interface UniqueGraphRequestBody {
  graphID: number;
  xAxisID: number;
  yAxisID: number;
  breakdownID?: number;
  currency: Currency;
  value: ChartValue;
}

export interface DeepDiveChartDataResponse {
  id: number;
  value: string;
  currency: Currency;
  dataJson: string;
  breakdownOptions: BreakdownOptions;
  xAxisOptions: AppSearchbarOption;
  yAxisOptions: AppSearchbarOption;
  chartPrimary: {
    acquisitionDate: string;
    chartType: MARKET_DATA_GRAPH_TYPES;
    deepDivePageId: number;
    id: number;
    marketDataSubsectionId: number;
  };
}

export enum GRAPH_HEADERS {
  UNIT = "Unit",
  BREAKDOWN = "Breakdown",
  VALUE = "Value",
  CATEGORY = "Category",
}

export enum VALUE_OPTIONS {
  ABSOLUTE = "Absolute",
  PERCENTAGE = "Percentage",
}

export enum X_AXIS_ID {
  TAG = 5,
  TIME = 1,
}

export enum MarketResearchModelSorting {
  ALPHABETICALLY = "ALPHABETICALLY",
  UPLOADED_DATE = "UPLOADED_DATE",
}

export interface MarketResearchModel {
  challengeAreaID?: number;
  manuallyCurated?: boolean;
  limit?: number;
  offset?: number;
  name?: string;
  sorting?: MarketResearchModelSorting;
  adminPanel?: boolean;
}

export interface MarketResearchResponse {
  count: number;
  items: DeepDivePage[];
}

export interface AllReportsTab extends AppTab {
  id: number;
}

export interface DeepDiveTakeaway {
  deepDivePageId: number;
  title: string;
  text: string;
  position: number | string;
  id?: number;
}

export interface KeyTakeawaySubsectionModel
  extends DeepDiveTakeaway,
    SubsectionModel {}

export type KeyTakeAwaysSection = SectionModel<KeyTakeawaySubsectionModel>;
