<template>
  <AppEditSidebar
    v-model="showSideBar"
    :title="isEdit ? 'Edit Fund' : 'Add Fund'"
  >
    <template #content>
      <AppInformationGroup label="Fund name" is-required>
        <AppInput
          v-model="fundData.name"
          :error="isEmpty(fundData.name)"
          placeholder="Fund name"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Fund focus" is-required>
        <AppSelect
          :options="focusOptions"
          v-model="fundData.thesis"
          :error="isEmpty(fundData.thesis)"
          searchable
          unselectable
          placeholder="Fund focus"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Fund type" is-required>
        <AppSelect
          :options="types"
          v-model="fundData.type"
          :error="isEmpty(fundData.type)"
          searchable
          unselectable
          placeholder="Fund type"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Investment instrument" is-required>
        <AppSelect
          :options="instruments"
          v-model="fundData.instrument"
          :error="isEmpty(fundData.instrument)"
          searchable
          unselectable
          placeholder="Investment instrument"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Website">
        <AppInput
          v-model="fundData.website"
          :error="!!fundData.website && !isValidUrl(fundData.website)"
          placeholder="Website"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Fund manager(s)">
        <AppSearchbar
          :is-loading="isManagerLoading"
          :options="managers"
          @select="handleManagerSearchbarSelect"
          :is-local-search="false"
          :debounce-function="fetchManagers"
          :debounce-duration="500"
          clearable
          :max-results="3"
          search-property="name"
          label-property="name"
          placeholder="Fund Manager's Name"
        >
          <template #result="{ option, text }">
            <template v-if="option.id === -1">
              No managers matching <strong>{{ text }}</strong> found
            </template>
            <div v-else class="overview-edit-section__form__manager">
              <AppTooltip content="Click to copy Manager's website">
                <AppIcon
                  @click.stop="copyToClipboard(formatInvestor(option))"
                  :src="copyGreen"
                />
              </AppTooltip>
              <span>{{ formatInvestor(option) }}</span>
            </div>
          </template>
          <template #append>
            <AppNewDropdownOption
              @click="handleOpenInvestorModal"
              title="New manager"
              description="Add to database"
            />
          </template>
        </AppSearchbar>

        <SelectedItemsList
          :list="selectedManagers"
          @remove="handleRemoveManager"
          flex-direction="row"
          gap="8px 16px"
          item-gap="8px"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Start date">
        <AppDatePicker
          v-model="fundData.startDate"
          v-bind="{
            ...(fundData.endDate && { maxDate: fundData.endDate }),
          }"
          placeholder="MM-DD-YYYY"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Close date">
        <AppDatePicker
          v-model="fundData.endDate"
          v-bind="{
            ...(fundData.startDate && { minDate: fundData.startDate }),
          }"
          placeholder="MM-DD-YYYY"
        />
      </AppInformationGroup>

      <AppInformationGroup label="Fund target size">
        <div class="overview-edit-section__form__money-field">
          <AppInput
            :model-value="formatedValues.targetSize"
            @update:model-value="
              updateFormatedValues($event, FundMoney.TARGET_SIZE)
            "
            :error="isMoneyNotValid(FundMoney.TARGET_SIZE)"
            placeholder="Fund manager"
          />

          <AppSelect
            :options="currencies"
            v-model="selectedCurrencies.targetSize"
            searchable
            label=""
            class="fieldxs"
          />
        </div>
      </AppInformationGroup>

      <AppInformationGroup label="Total capital raised">
        <div class="overview-edit-section__form__date-input-field">
          <div class="overview-edit-section__form__money-field">
            <AppInput
              :model-value="formatedValues.totalCapitalRaised"
              @update:model-value="
                updateFormatedValues($event, FundMoney.TOTAL_CAPITAL_RAISED)
              "
              :error="isMoneyNotValid(FundMoney.TOTAL_CAPITAL_RAISED)"
              placeholder="Fund manager"
            />

            <AppSelect
              :options="currencies"
              v-model="selectedCurrencies.totalCapitalRaised"
              searchable
              placeholder="$ USD"
              class="fieldxs"
            />
          </div>

          <AppDatePicker
            v-model="fundData.totalCapitalRaisedDate"
            placeholder="MM-DD-YYYY"
          />
        </div>
      </AppInformationGroup>

      <AppInformationGroup label="Deployed capital">
        <div class="overview-edit-section__form__date-input-field">
          <div class="overview-edit-section__form__money-field">
            <AppInput
              :model-value="formatedValues.deployedCapital"
              @update:model-value="
                updateFormatedValues($event, FundMoney.DEPLOYED_CAPITAL)
              "
              :error="isMoneyNotValid(FundMoney.DEPLOYED_CAPITAL)"
              placeholder="Fund manager"
            />

            <AppSelect
              :options="currencies"
              v-model="selectedCurrencies.deployedCapital"
              searchable
              placeholder=""
              class="fieldxs"
            />
          </div>

          <AppDatePicker
            v-model="fundData.deployedCapitalDate"
            placeholder="MM-DD-YYYY"
          />
        </div>
      </AppInformationGroup>

      <AppInformationGroup label="Realised value">
        <div class="overview-edit-section__form__money-field">
          <AppInput
            :model-value="formatedValues.realisedValue"
            @update:model-value="
              updateFormatedValues($event, FundMoney.REALISED_VALUE)
            "
            :error="isMoneyNotValid(FundMoney.REALISED_VALUE)"
            placeholder="Fund manager"
          />
          <AppSelect
            :options="currencies"
            v-model="selectedCurrencies.realisedValue"
            searchable
            placeholder=""
            class="fieldxs"
          />
        </div>
      </AppInformationGroup>

      <AppInformationGroup label="Unrealised value">
        <div class="overview-edit-section__form__money-field">
          <AppInput
            :model-value="formatedValues.unrealisedValue"
            @update:model-value="
              updateFormatedValues($event, FundMoney.UNREALISED_VALUE)
            "
            :error="isMoneyNotValid(FundMoney.UNREALISED_VALUE)"
            placeholder="Fund manager"
          />
          <AppSelect
            :options="currencies"
            v-model="selectedCurrencies.unrealisedValue"
            searchable
            placeholder=""
            class="fieldxs"
          />
        </div>
      </AppInformationGroup>

      <AppInformationGroup label="Net IRR">
        <AppInput v-model="fundData.netIRR" placeholder="Fund manager" />
      </AppInformationGroup>

      <AppInformationGroup label="Vintage year">
        <AppInput v-model="fundData.vintageYear" placeholder="Fund manager" />
      </AppInformationGroup>
    </template>

    <template #submit-button>
      <AppButton
        @click="saveAndRedirect"
        :is-disabled="isDisabledSubmit"
        :is-loading="isSaveLoading"
        :label="isEdit ? 'Save' : 'Add'"
      />
    </template>
  </AppEditSidebar>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import AppButton from "@/components/app/AppButton/AppButton.vue";
import AppInput from "@/components/app/AppInput/AppInput.vue";
import AppSearchbar from "@/components/app/AppSearchbar/AppSearchbar.vue";
import AppSelect from "@/components/app/AppSelect/AppSelect.vue";
import AppNewDropdownOption from "@/components/app/AppNewDropdownOption/AppNewDropdownOption.vue";
import AppDatePicker from "@/components/app/AppDatePicker/AppDatePicker.vue";
import AppInformationGroup from "@/components/app/AppInformationGroup/AppInformationGroup.vue";
import AppEditSidebar from "@/components/app/AppEditSidebar/AppEditSidebar.vue";
import SelectedItemsList from "@/components/admin/SelectedItemsList/SelectedItemsList.vue";
import AppTooltip from "@/components/app/AppTooltip/AppTooltip.vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import fundService from "@/shared/services/fundService/fundService";
import fundingRoundsService from "@/shared/services/fundingRoundsService/fundingRoundsService";
import { AppSearchbarOption } from "@/shared/types/components";
import { FundMoney, BaseFundItem } from "@/shared/types/fund";
import { useFundOverviewEdit } from "@/shared/composables/useFundOverviewEdit/useFundOverviewEdit";
import { copyGreen } from "@/shared/constants/icons";
import { isValidUrl, isEmpty } from "@/shared/helpers/validators/validators";
import { copyToClipboard } from "@/shared/helpers/copyToClipboard/copyToClipboard";
import { TBInvestor } from "@/shared/types/filters";
import { isFulfilled } from "@/shared/helpers/isFulfilled/isFulfilled";
import { AxiosError } from "axios";
import { abortErrorName } from "@/shared/constants/errors";

const route = useRoute();
const router = useRouter();

const { isEdit } = defineProps<{
  isEdit?: boolean;
}>();

const emit = defineEmits<{
  (e: "open-investor-popup"): void;
}>();

const showSideBar = defineModel<boolean>({ required: true });

const {
  isDisabledSubmit,
  selectedCurrencies,
  selectedManagers,
  formatedValues,
  fundData,
  updateFormatedValues,
  formatInvestor,
  isMoneyNotValid,
  setFundData,
  handleManagerSearchbarSelect,
  handleRemoveManager,
  save,
} = useFundOverviewEdit();

let abortController: AbortController;

const isManagerLoading = ref(false);
const isSaveLoading = ref(false);

const managers = ref<TBInvestor[]>([]);

const types = ref<BaseFundItem[]>([]);
const focusOptions = ref<BaseFundItem[]>([]);
const instruments = ref<BaseFundItem[]>([]);
const currencies = ref<AppSearchbarOption[]>([]);

const handleOpenInvestorModal = () => {
  emit("open-investor-popup");
};

const fetchManagers = async (newName: string) => {
  try {
    isManagerLoading.value = true;
    if (abortController) {
      abortController.abort();
    }

    abortController = new AbortController();

    const { data } = await fundingRoundsService.getInvestorsByName(
      newName,
      abortController.signal,
    );

    managers.value = data.length ? data : [{ id: -1, name: "" } as TBInvestor];

    isManagerLoading.value = false;
  } catch (error) {
    managers.value = [];
    if ((error as AxiosError).name !== abortErrorName) {
      isManagerLoading.value = false;
    }
  }
};

watch(
  () => selectedCurrencies,
  () => {
    fundData.value.targetSizeCurrency = selectedCurrencies.targetSize.label;
    fundData.value.totalCapitalRaisedCurrency =
      selectedCurrencies.totalCapitalRaised.label;
    fundData.value.deployedCapitalCurrency =
      selectedCurrencies.deployedCapital.label;
    fundData.value.realisedValueCurrency =
      selectedCurrencies.realisedValue.label;
    fundData.value.unrealisedValueCurrency =
      selectedCurrencies.unrealisedValue.label;
  },
  {
    deep: true,
  },
);

watch(
  () => selectedManagers.value,
  () => {
    fundData.value.investorIDs = selectedManagers.value.map(
      (manager: AppSearchbarOption) => Number(manager.investorID),
    );
  },
  {
    deep: true,
  },
);

watch(
  () => fundData.value,
  () => {
    fundData.value.thesisId = fundData.value.thesis?.id;
    fundData.value.typeId = fundData.value.type?.id;
    fundData.value.instrumentId = fundData.value.instrument?.id;
  },
  {
    deep: true,
  },
);

(async () => {
  try {
    const [fundTypes, fundTheses, fundInstruments, currenciesData] =
      await Promise.allSettled([
        fundService.getFundTypes(),
        fundService.getFundTheses(),
        fundService.getFundInstruments(),
        fundingRoundsService.getCurrencies(),
      ]);

    if (isFulfilled(fundTypes)) {
      types.value = fundTypes.value.data;
    }
    if (isFulfilled(fundTheses)) {
      focusOptions.value = fundTheses.value.data;
    }
    if (isFulfilled(fundInstruments)) {
      instruments.value = fundInstruments.value.data;
    }
    if (isFulfilled(currenciesData)) {
      currencies.value = currenciesData.value.data.map((currency: string) => ({
        label: currency,
        value: currency,
      }));
    }
    setFundData();
  } catch (_) {
    types.value = [] as BaseFundItem[];
    focusOptions.value = [] as BaseFundItem[];
    instruments.value = [] as BaseFundItem[];
    currencies.value = [] as AppSearchbarOption[];
  }
})();

const saveAndRedirect = async () => {
  try {
    isSaveLoading.value = true;
    const savedFund = await save();
    showSideBar.value = false;

    if (!route.params.id) {
      await router.push(`/fund/${savedFund.id}`);
    }
  } catch (error) {
    console.error("Error saving fund:", error);
  } finally {
    isSaveLoading.value = false;
  }
};
</script>

<style scoped lang="scss">
@import "@/styles/functions.scss";
@import "@/styles/colors.scss";

.overview-edit-section__form {
  &__manager {
    display: flex;
    gap: rem(5px);
  }

  &__money-field {
    display: flex;
    align-items: end;

    &:deep(.app-input) {
      border-radius: rem(8px) 0 0 rem(8px);
      border-right: 0;
    }

    &:deep(.app-select) {
      border-radius: 0 rem(8px) rem(8px) 0;
    }
  }

  &__date-input-field {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: rem(12px);
  }
}
</style>
