import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, unref as _unref, isRef as _isRef, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "flex justify-between items-center fixed w-full h-16 px-8 z-10 bg-white shadow" }
const _hoisted_2 = { class: "flex items-center gap-4" }

import { ref, computed } from "vue";
import AppFloatingMenu from "@/components/app/AppFloatingMenu/AppFloatingMenu.vue";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { useSearchResults } from "@/shared/composables/useSearchResults/useSearchResults";
import { useNavigationMenu } from "@/shared/composables/useNavigationMenu/useNavigationMenu";
import { useSearchNavigation } from "@/shared/composables/useSearchNavigation/useSearchNavigation";
import { ClaimProfileSteps } from "@/shared/types/profileClaim";
import AppNewCompanyModal from "@/components/dashboard/AppNewCompanyModal/AppNewCompanyModal.vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import SearchForm from "@/components/app/AppHeader/components/SearchForm.vue";
import ActionButtons from "@/components/app/AppHeader/components/ActionButtons.vue";
import UserAvatar from "@/components/app/AppHeader/components/UserAvatar.vue";
import { NewCompanyModel } from "@/shared/types/organization";
import organizationsService from "@/shared/services/organizationsService/organizationsService";
import { defaultServerErrorMessage } from "@/shared/constants/errors";
import { redirect } from "@/shared/helpers/redirect/redirect";
import { AxiosError } from "axios";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import FundOverviewEditSection from "@/components/funds/FundOverviewEditSection/FundOverviewEditSection.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppHeader',
  setup(__props) {

const webCustomerStore = useWebCustomerStore();
const { searchResultsText } = useSearchResults();
const notificationsStore = useNotificationsStore();
const { menuOptions } = useNavigationMenu();
useSearchNavigation({
  searchText: searchResultsText,
});

const isFloatingMenuOpen = ref(false);
const showAddCompanyModal = ref(false);
const showAddFundModal = ref(false);
const isLoading = ref(false);

const userInitials = computed(() => {
  const { name, surname } = webCustomerStore.webCustomer;

  if (!name || !surname) return "";

  return `${name[0]}${surname[0]}`.toUpperCase();
});

const claimProfileURL = computed(() => {
  return `/claim-profile/${webCustomerStore.webCustomer.profileClaimToken}/${ClaimProfileSteps.BASIC_INFORMATION}`;
});

const handleAddNewCompany = async (newCompany: NewCompanyModel) => {
  try {
    isLoading.value = true;
    const { data } = await organizationsService.addNewCompany(newCompany);

    if (data.directURL) {
      redirect(`/organization/${data.directURL}`);
      showAddCompanyModal.value = false;
    }
  } catch (e) {
    const isInvalidUrl = (e as AxiosError).response?.status === 400;
    notificationsStore.notify(
      isInvalidUrl ? "Invalid URL" : defaultServerErrorMessage,
      "danger",
      3000,
    );
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("header", _hoisted_1, [
    _createVNode(AppLink, {
      to: "/dashboard",
      "link-component": "router-link"
    }, {
      default: _withCtx(() => [
        _createVNode(BaseIcon, {
          icon: "netZeroInsights",
          alt: "Net Zero Insights",
          size: "100px auto",
          class: "mt-1"
        })
      ]),
      _: 1
    }),
    _createVNode(SearchForm, {
      modelValue: _unref(searchResultsText),
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_isRef(searchResultsText) ? (searchResultsText).value = $event : null))
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ActionButtons, {
        "web-customer": _unref(webCustomerStore).webCustomer,
        "claim-profile-url": claimProfileURL.value,
        "show-add-company-modal": showAddCompanyModal.value,
        "onUpdate:showAddCompanyModal": _cache[1] || (_cache[1] = ($event: any) => ((showAddCompanyModal).value = $event)),
        "show-add-fund-modal": showAddFundModal.value,
        "onUpdate:showAddFundModal": _cache[2] || (_cache[2] = ($event: any) => ((showAddFundModal).value = $event))
      }, null, 8, ["web-customer", "claim-profile-url", "show-add-company-modal", "show-add-fund-modal"]),
      _createVNode(AppFloatingMenu, {
        modelValue: isFloatingMenuOpen.value,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((isFloatingMenuOpen).value = $event)),
        options: _unref(menuOptions),
        side: "right"
      }, {
        content: _withCtx(() => [
          _createVNode(UserAvatar, {
            initials: userInitials.value,
            modelValue: isFloatingMenuOpen.value,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((isFloatingMenuOpen).value = $event))
          }, null, 8, ["initials", "modelValue"])
        ]),
        _: 1
      }, 8, ["modelValue", "options"])
    ]),
    _createVNode(AppNewCompanyModal, {
      modelValue: showAddCompanyModal.value,
      "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((showAddCompanyModal).value = $event)),
      "is-loading": isLoading.value,
      name: _unref(searchResultsText),
      onAdd: handleAddNewCompany
    }, null, 8, ["modelValue", "is-loading", "name"]),
    _createVNode(FundOverviewEditSection, {
      modelValue: showAddFundModal.value,
      "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((showAddFundModal).value = $event)),
      "is-edit": false
    }, null, 8, ["modelValue"])
  ]))
}
}

})