import { defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, unref as _unref, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "app-sidebar group" }
const _hoisted_2 = { class: "app-sidebar__list" }

import { AppSidebarLink } from "@/shared/types/components";
import AppSidebarItem from "@/components/app/AppSidebar/AppSidebarItem/AppSidebarItem.vue";
import { useActiveSidebarLinks } from "@/components/app/AppSidebar/composables/useActiveLink";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppSidebar',
  props: {
    links: {}
  },
  setup(__props: any) {



const { activeLinks } = useActiveSidebarLinks(__props.links);

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("aside", _hoisted_1, [
    _createElementVNode("ul", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.links, (item) => {
        return (_openBlock(), _createBlock(AppSidebarItem, {
          key: item.name,
          item: item,
          "is-parent-active": item.path === _unref(activeLinks).parentPath,
          "active-child-path": _unref(activeLinks).childPath
        }, null, 8, ["item", "is-parent-active", "active-child-path"]))
      }), 128))
    ])
  ]))
}
}

})